<template>
  <div class="docs-main">
    <documento ref="docApp"/>
    <tipo-documentos :active="active" @selected="selectCategory" @search="search"/>
    <div class="doc-box box-main">
      <div class="header doc-menu">
        <div class="actions">
          <u-btn :disable="hasSelecteds" @click="criarDoc">
            <u-icon name="plus" type="fa"/>
          </u-btn>
          <u-btn :disable="!hasSelecteds || (selecteds && selecteds.length > 1)" @click="editSelected">
            <u-icon name="pen" type="fa"/>
          </u-btn>
          <u-btn :disable="!hasSelecteds" @click="removeSelected">
            <u-icon name="trash-alt" type="fa"/>
          </u-btn>
          <div class="diviser"></div>
          <u-btn :disable="!hasSelecteds || (selecteds && selecteds.length > 1)" @click="editSelected">
            <u-icon name="eye" type="fa"/>
          </u-btn>
          <u-btn :disable="true">
            <u-icon name="print" type="fa"/>
          </u-btn>
          <div class="diviser"></div>
          <u-btn disable>
            <u-icon name="code-merge" type="fa"/>
          </u-btn>
          <u-btn disable>
            <u-icon name="signature" type="fa"/>
          </u-btn>
          <u-btn v-if="loading">
            <sl-loading class="size-18" content="" />
          </u-btn>
        </div>
        <div class="tab">
          <div class="tab-btn active">
            <u-icon name="th-list" type="fa" icon-style="regular" class="m-r-sm m-l-n-lg"/>
            Documentos
          </div>
        </div>
      </div>
      <div class="doc-box box-content">
        <div class="header header-table no-padder">
          <doc-th class="select">
            <input v-model="selectAll" type="checkbox"/>
          </doc-th>
          <doc-th class="type">
            TP
          </doc-th>
          <doc-th class="doc-th-main">
            <doc-th class="doc-th-main1">
              Documento
            </doc-th>
            <doc-th class="doc-th-main2 flex row">
              <doc-th class="title">
                Nome
              </doc-th>
              <doc-th class="id">
                Identificador
              </doc-th>
              <doc-th class="category">
                Categoria
              </doc-th>
              <doc-th class="revision">
                Revisão
              </doc-th>
              <doc-th class="date">
                Data
              </doc-th>
              <doc-th class="hits">
                Hits
              </doc-th>
            </doc-th>
          </doc-th>
        </div>
        <div class="content">
          <div class="content-data">
            <div @click="(e) => abrirDoc(doc, e)" v-for="doc in documents" class="table-line"
                 :class="{selected: doc.selected}">
              <doc-td class="select">
                <input v-model="doc.selected" type="checkbox"/>
              </doc-td>
              <doc-td class="type">
                <img src="../assets/ico-html-min.png" width="20" style="width: 20px">
              </doc-td>
              <doc-td class="title">
                {{ doc.name }}
              </doc-td>
              <doc-td class="id">
                {{ doc.id }}
              </doc-td>
              <doc-td class="category">
                {{ doc.categoria.name }}
              </doc-td>
              <doc-td class="revision">
                {{ doc.revisoes ? doc.revisoes.length : 0 }}
              </doc-td>
              <doc-td class="date">
                {{ doc.createdAt|formatDate('dd/MM/yyyy') }}
              </doc-td>
              <doc-td class="hits">
                {{ doc.hits }}
              </doc-td>
            </div>
            <div v-if="loading" class="table-line__" style="">
              <doc-td class="flex full-height full-width items-center justify-center m-t-lg">
                <sl-loading class="size-18" content=""/>
              </doc-td>
            </div>
            <div v-else-if="!loading && (!documents || !documents.length)" class="table-line__">
              <doc-td class="flex full-height full-width items-center justify-center m-t-lg">
                Nenhum documento encontrado com os critérios de pesquisa.
              </doc-td>
            </div>
          </div>
          <div class="content-pagination footer">
            <div class="actions">
              <div class="total flex items-center m-r-sm text-grey-7">
                Total de registros: {{ pagination.totalItens }}
              </div>
              <u-btn :disable="disablePagination">
                <u-icon name="caret-left" type="fa"/>
              </u-btn>
              <div class="diviser"></div>
              <u-input :disable="disablePagination" hide-underline v-model="pagination.page"/>
              <div class="diviser"></div>
              <u-btn :disable="disablePagination">
                <u-icon name="caret-right" type="fa"/>
              </u-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UInput, debounce, UProgress} from "uloc-vue"
import DocTh from "@/components/documentos/components/list/Th"
import DocTd from "@/components/documentos/components/list/Td"
import TipoDocumentos from "@/components/documentos/components/list/TipoDocumentos"
import SlLoading from "@/components/layout/components/Loading"
import {listDocumentos, deleteDocumento} from "@/domain/documento/services"
import Documento from "@/components/documentos/components/Documento"
import documentoWindow from "components/documentos/components/window/documento"

export default {
  name: "Documentos",
  components: {
    Documento,
    SlLoading,
    TipoDocumentos,
    DocTd,
    DocTh,
    UInput,
    // UProgress
  },
  data() {
    return {
      loading: false,
      active: null,
      documents: null,
      selectAll: false,
      pagination: {
        limit: 50,
        page: 1,
        totalItens: 0,
        totalPages: 0
      }
    }
  },
  watch: {
    selectAll(v) {
      this.documents && this.documents.map(d => {
        d.selected = v
      })
    },
    hasSelecteds(v) {
      if (!v) {
        this.selectAll = false
      }
    }
  },
  mounted() {
    this.listAll()
  },
  computed: {
    disablePagination() {
      return true
    },
    selecteds() {
      return this.documents && this.documents.filter(d => d.selected)
    },
    hasSelecteds() {
      return !!(this.selecteds && this.selecteds.length)
    }
  },
  methods: {
    listAll (filter = '', sortBy = 'nome', descending = false) {
      this.loading = true
      listDocumentos(this.pagination.limit, this.pagination.page, '&' + filter + `&sortBy=${sortBy}&sortDesc=${descending}`)
          .then(response => {
            let result = response.data.result
            if (result && result.length) {
              result = result.map(doc => {
                return {
                  ...doc,
                  selected: false
                }
              })
            }
            this.documents = result
            this.pagination.totalItens = response.data.total
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    search: debounce(function (v) {
      this.listAll('search=' + v)
    }, 500),
    selectCategory(c) {
      this.active = c
      this.documents = null
      this.loading = true
      listDocumentos(this.pagination.limit, this.pagination.page, `&categoria=${c.id}`)
          .then(response => {
            let result = response.data.result
            if (result && result.length) {
              result = result.map(doc => {
                return {
                  ...doc,
                  selected: false
                }
              })
            }
            this.documents = result
            this.pagination.totalItens = response.data.total
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    documentoWindow,
    criarDoc() {
      this.documentoWindow()
      //this.$refs.docApp.show()
    },
    abrirDoc(doc, e) {
      if (e.target.tagName.toUpperCase() === 'INPUT') return
      this.documentoWindow(doc.id)
      //this.$refs.docApp.show(doc.id)
    },
    editSelected() {
      console.log('Edit ', this.selecteds)
      this.selecteds && this.selecteds.length && this.$refs.docApp.show(this.selecteds[0].id)
    },
    removeSelected() {
      if (!this.hasSelecteds) {
        return
      }
      this.confirmarExclusao('Tem certeza que deseja excluir os itens selecionados? Lembre-se que se este documento estiver sendo utilizado em alguma parte do sistema, o funcionamento do mesmo será prejudicado. Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'autorizo excluir')
          .then(() => {
            this.$uloc.loading.show()
            this.selecteds.map(doc => {
              deleteDocumento(doc.id)
                  .then(() => {
                    this.$uloc.loading.hide()
                    this.$uloc.notify({
                      color: 'positive',
                      message: `Documento excluído com sucesso.`
                    })
                    this.selectCategory(this.active)
                  })
                  .catch(error => {
                    this.$uloc.loading.hide()
                    this.alertApiError(error)
                  })
            })
          })
          .catch(() => {
          })
    }
  }
}
</script>
